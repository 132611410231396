import { action, flow, makeObservable, observable } from 'mobx';

import { HttpResponse } from '../../core/classes/HttpResponse';
import { popupManagerService } from './popupManagerService';

class PopupManagerStore {

  popups = new HttpResponse({ items: [], count: 0 });

  popupDetails = new HttpResponse({});

  constructor() {
    makeObservable(this, {

      popups: observable,
      setPopups: action.bound,
      getPopups: flow,

      popupDetails: observable,
      setPopupDetails: action.bound,
      getPopupDetails: flow

    });
  }

  setPopups = (data) => {
    this.popups = data;
  }
  resetPopups = () => {
    this.setPopups(this.popups.fetching(true));
  };
  getPopups = function* (params) {
    this.setPopups(this.popups.fetching());
    const data = yield popupManagerService.getPopups(params);
    this.setPopups(this.popups.fetched(data));
  };

  setPopupDetails = (data) => {
    this.popupDetails = data;
  }
  resetPopupDetails = () => {
    this.setPopupDetails(this.popupDetails.fetching(true));
  }
  getPopupDetails = function* (id) {
    this.setPopupDetails(this.popupDetails.fetching());
    const data = yield popupManagerService.getPopupDetails(id);
    this.setPopupDetails(this.popupDetails.fetched(data));
  };

  addPopup = (data) => {
    const formData = this._generatePopupFormData(data);
    return popupManagerService.addPopup(formData);
  }

  editPopup = (data) => {
    const formData = this._generatePopupFormData(data);
    return popupManagerService.editPopup(formData);
  }

  _generatePopupFormData = (data) => {

    const formData = new FormData();

    const { id, name, startDate, endDate, forLobby, partnerIds, gameIds, popUpLanguages } = data;

    if (id) {
      formData.append('id', id);
    }
    formData.append('name', name);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('forLobby', forLobby);
    partnerIds.forEach((partnerId, index) => {
      formData.append(`partnerIds[${index}]`, partnerId);
    });
    gameIds.forEach((gameId, index) => {
      formData.append(`gameIds[${index}]`, gameId);
    });
    popUpLanguages.forEach(({ languageCode, popUpImage }, index) => {
      formData.append(`popUpLanguages[${index}].languageCode`, languageCode);
      formData.append(`popUpLanguages[${index}].popUpImage`, popUpImage, popUpImage.name);
    });

    return formData;
  }

  cancelPopup = (id) => {
    return popupManagerService.cancelPopup(id);
  }

}

export const popupManagerStore = new PopupManagerStore();