import { ApiVersion } from '../../core/enums/ApiVersion';
import { httpClient, httpClientV2 } from '../httpClient/httpClient';
import {
  popupsUrl,
  cacnelPopupUrl,
  popupDetailsUrl
} from '../../core/constants/endpoints';
import { abortFetch } from '../../core/helpers/abortController';
import { ABORT_API_KEYS } from '../../core/constants/abortApiKeys';

class PopupManagerService {

  async getPopups(params = {}, option = { version: ApiVersion.V1 }) {
    const signal = abortFetch(ABORT_API_KEYS.GetPopups);
    return await httpClientV2.get(popupsUrl(option), { params, signal });
  }

  async addPopup(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.post(popupsUrl(option),  data);
  }

  async editPopup(data = {}, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(popupsUrl(option),  data);
  }

  async cancelPopup(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.put(cacnelPopupUrl(id, option));
  }

  async getPopupDetails(id, option = { version: ApiVersion.V1 }) {
    return await httpClient.get(popupDetailsUrl(id, option));
  }

}

export const popupManagerService = new PopupManagerService();
