import LocalStorageHelper from '../helpers/localStorageHelper';
import { ACCESS } from './util';

export const getIsAuthDefaultValue = () => {
  try {
    const access = LocalStorageHelper.getItem(ACCESS);
    return !!(access && access.expiresIn && access.expiresIn > Date.now());
  } catch (error) {
    return false;
  }
};
