import { PERMISSIONS } from './permissions';
import { TranslationKeys } from './translationKeys';

export const navigations = [
  {
    text: TranslationKeys.DASHBOARD,
    to: '/dashboard',
    path: '/dashboard',
    icon: 'bc-icon-dashboard',
    permissions: [PERMISSIONS.ViewDashboard],
    showOnMobile: true
  },
  {
    text: TranslationKeys.PLATFORM,
    to: '/platform',
    path: '/platform',
    icon: 'bc-icon-players-rmt-36',
    permissions: [
      PERMISSIONS.ViewPlatformSettings,
      PERMISSIONS.ViewPartnerSettings,
      PERMISSIONS.ViewGames,
      PERMISSIONS.ViewGameTypes
    ]
  },
  {
    text: TranslationKeys.REPORTS,
    to: '/reports',
    path: '/reports',
    icon: 'bc-icon-report',
    permissions: [
      PERMISSIONS.ViewBogBetReport,
      PERMISSIONS.ViewBogUserReport,
      PERMISSIONS.ViewBogRoundReport,
      PERMISSIONS.ViewArcadeBetReport,
      PERMISSIONS.ViewArcadeUserReport,
      PERMISSIONS.ViewTableBetReport,
      PERMISSIONS.ViewTableUserReport,
      PERMISSIONS.ViewTableRoundReport,
      PERMISSIONS.ViewLottoBetReport,
      PERMISSIONS.ViewLottoRoundReport,
      PERMISSIONS.ViewLottoUserReport,
      PERMISSIONS.ViewBetshopBetReport,
      PERMISSIONS.ViewBetshopRoundReport,
      PERMISSIONS.ViewBetshopUserReport,
      PERMISSIONS.ViewSlotBetReport,
      PERMISSIONS.ViewSlotUserReport,
      PERMISSIONS.ViewFinancialReport,
      PERMISSIONS.ViewTransactionReport,
      PERMISSIONS.ViewFreebetReport,
      PERMISSIONS.ViewPlayerPromoReport,
      PERMISSIONS.ViewBetshopMarketsReport,
      // PERMISSIONS.ViewTournamentReport, //TODO
    ],
    showOnMobile: true
  },
  {
    text: TranslationKeys.ADMIN,
    to: '/admin',
    path: '/admin',
    icon: 'bc-icon-user',
    permissions: [
      PERMISSIONS.ViewUserManagement,
      PERMISSIONS.ViewUnderMaintenance,
      PERMISSIONS.ViewStaticContent,
      PERMISSIONS.ViewLanguages,
      PERMISSIONS.ViewApplications
    ]
  },
  {
    text: TranslationKeys.BONUS,
    to: '/bonus',
    path: '/bonus',
    icon: 'bc-icon-promotional-bonus-48',
    permissions: [
      PERMISSIONS.ViewBonusIsland,
      PERMISSIONS.ViewBonusIslandList,
      PERMISSIONS.ViewFreebet
    ]
  },
  {
    text: TranslationKeys.TRANSLATION_MANAGER,
    to: '/translation-tool',
    path: '/translation-tool',
    icon: 'bc-icon-note',
    permissions: [PERMISSIONS.ViewTranslations]
  },
  {
    text: TranslationKeys.PG_TOURNAMENTS,
    to: '/pg-tournament',
    path: '/pg-tournament',
    icon: 'bc-icon-assign-tournament',
    permissions: [PERMISSIONS.ViewTournamentList],
  },
  {
    text: TranslationKeys.POPUP_MANAGER,
    to: '/popup-manager',
    path: '/popup-manager',
    icon: 'bc-icon-pop-ups',
    permissions: [PERMISSIONS.ViewPopupManager],
  },
  {
    text: TranslationKeys.PG_LOBBY,
    to: '/pg-lobby',
    path: '/pg-lobby',
    icon: 'bc-icon-favorite-fill',
    permissions: [PERMISSIONS.ViewLobbyList],
  },
];
