import { httpClient } from '../httpClient/httpClient';
import {
  refreshTokenUrlConstant,
} from '../../core/constants/endpoints';

class AuthService {

  /**
   * @name refreshToken
   * @description this endpoint need for refresh Token
   * @return {Promise<AuthTokenResponse>}
   */
  async refreshToken() {
    return await httpClient.post(refreshTokenUrlConstant);
  }

}

export const authService = new AuthService();
