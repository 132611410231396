import { action, flow, makeObservable, observable } from 'mobx';

import { boostedJackpotService } from './boostedJackpotService';
import { HttpResponse } from '../../core/classes/HttpResponse';

class BoostedJackpotStore {

  boostedJackpots = new HttpResponse({ items: [], count: 0 });

  boostedJackpotDetails = new HttpResponse({});
  
  playerBoostedJackpotReports = new HttpResponse({ items: [], count: 0 });

  constructor() {
    makeObservable(this, {

      boostedJackpots: observable,
      setBoostedJackpots: action.bound,
      getBoostedJackpots: flow,

      boostedJackpotDetails: observable,
      setBoostedJackpotDetails: action.bound,
      getBoostedJackpotDetails: flow,

      playerBoostedJackpotReports: observable,
      setPlayerBoostedJackpotReports: action.bound,
      getPlayerBoostedJackpotReports: flow,

    });
  }

  setBoostedJackpots = (data) => {
    this.boostedJackpots = data;
  }
  resetBoostedJackpots = () => {
    this.setBoostedJackpots(this.boostedJackpots.fetching(true));
  };
  getBoostedJackpots = function* (params) {
    this.setBoostedJackpots(this.boostedJackpots.fetching());
    const data = yield boostedJackpotService.getBoostedJackpots(params);
    this.setBoostedJackpots(this.boostedJackpots.fetched(data));
  };

  setBoostedJackpotDetails = (data) => {
    this.boostedJackpotDetails = data;
  }
  resetBoostedJackpotDetails = () => {
    this.setBoostedJackpotDetails(this.boostedJackpotDetails.fetching(true));
  }
  getBoostedJackpotDetails = function* (id) {
    this.setBoostedJackpotDetails(this.boostedJackpotDetails.fetching());
    const data = yield boostedJackpotService.getBoostedJackpotDetails(id);
    this.setBoostedJackpotDetails(this.boostedJackpotDetails.fetched(data));
  };

  setPlayerBoostedJackpotReports = (data) => {
    this.playerBoostedJackpotReports = data;
  }
  getPlayerBoostedJackpotReports = function* (params) {
    this.setPlayerBoostedJackpotReports(this.playerBoostedJackpotReports.fetching());
    const data = yield boostedJackpotService.getPlayerBoostedJackpotReports(params);
    this.setPlayerBoostedJackpotReports(this.playerBoostedJackpotReports.fetched(data));
  };

  addBoostedJackpot = (data) => {
    return boostedJackpotService.addBoostedJackpot(data);
  }

  editBoostedJackpot = (data) => {
    return boostedJackpotService.editBoostedJackpot(data);
  }

  cancelBoostedJackpot = (id) => {
    return boostedJackpotService.cancelBoostedJackpot(id);
  }

}

export const boostedJackpotStore = new BoostedJackpotStore();