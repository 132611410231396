import { action, makeObservable, observable } from 'mobx';

import LocalStorageHelper from '../../core/helpers/localStorageHelper';
import { getIsAuthDefaultValue } from '../../core/constants/authUtil';
import { signOutUrl } from '../../core/constants/endpoints';
import { ACCESS } from '../../core/constants/util';
import { authService } from './authService';
import { appModule } from '../appModule';

class AuthStore {

  isAuth = getIsAuthDefaultValue();

  expirationInsurance = 100;

  refreshTokenTimer = null;

  constructor() {
    makeObservable(this, {
      isAuth: observable.struct,
      refreshTokenTimer: observable.ref,
      setAuth: action,
      refreshToken: action,
      signOut: action,
    });
  }

  /**
   * @name setAuth
   * @param {boolean} value
   * @returns {void}
   */
  setAuth = (value) => {
    this.isAuth = value;
    if (!this.isAuth) {
      appModule.redirectToSSOLogin();
    }
  };

  /**
   * @name refreshToken
   * @returns {Promise<AuthTokenResponse>}
   */
  refreshToken = async () => {
    const response = await authService.refreshToken();
    this._attachTimer(response);
  };

  /**
   * @name _setupAccessInMemory
   * @param {AuthTokenResponse} response
   * @private
   */
  _setupAccessInMemory = (response) => {
    LocalStorageHelper.deleteItem(ACCESS);
    response.expiresIn = (response.expiresIn - this.expirationInsurance) * 1000 + Date.now();
    LocalStorageHelper.setItem(ACCESS, response);
  };

  /**
   * @name _attachTimer
   * @param {AuthTokenResponse} response
   * @private
   */
  _attachTimer = (response) => {
    clearTimeout(this.refreshTokenTimer);
    const timer = (response.expiresIn - this.expirationInsurance) * 1000;
    this._setupAccessInMemory(response);
    this.refreshTokenTimer = setTimeout(() => {
      authService.refreshToken()
        .then(res => {
          this._attachTimer(res);
          this._setupAccessInMemory(res);
        });
    }, timer);
  };

  /**
   * @name signOut
   * @returns {Promise<void>}
   */
  signOut = async () => {
    LocalStorageHelper.deleteItem(ACCESS);
    window.location.href = signOutUrl(Date.now());
    clearTimeout(this.refreshTokenTimer);
  };
}

export const authStore = new AuthStore();
