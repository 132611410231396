import React, { useEffect, useState, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { authStore, appModule } from '../../../module';
import { Loader } from '../../components/shared';

const Splash = observer(({
  history,
  location,
  children,
}) => {
  const { isAuth } = authStore;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuth) {
      setLoading(true);
    }
    appModule.init().then(isValid => {
      authStore.setAuth(!!isValid);
      if (isValid) {
        history.push({
          path: location.pathname,
          search: location.search,
        });
      }
      setLoading(false);
    });
  }, [isAuth]);

  return isLoading
    ? (
      <div className="splash__busy__loader_container">
        <Loader isBusy type="spinner" spinnerSize="big"/>
      </div>
    )
    : (
      <Suspense
        fallback={(
          <Loader isBusy type="spinner"/>
        )}
      >
        {children}
      </Suspense>
    );
});

export default withRouter(Splash);
