import { USER_BLOCKED_ERR_MESSAGE } from '../../core/constants/apiConstants';
import { TranslationKeys as T } from '../../core/constants/translationKeys';
import { maintenanceStore } from '../maintenancesModule';
import { HTTPStatus } from '../../core/enums/HTTPStatus';
import { notifyService } from '../notifyModule';
import { userStore } from '../userModule';
import { authStore } from '../authModule';
import { appModule } from '../appModule';

class ErrorHandlerService {
  async handle({ response = {} }) {
    if (
      response.status === HTTPStatus.NotImplemented
      || response.status === HTTPStatus.BadGataway
      || response.status === HTTPStatus.ServiceUnavailable 
      || response.status === HTTPStatus.GatawayTimeOut
      || response.status === HTTPStatus.NotSupportedVersion
    ) {
      maintenanceStore.setIsUnderMaintenance(true);
    } else if (response.data?.errorMessage === USER_BLOCKED_ERR_MESSAGE) {
      const { _userName } = userStore.blockedUser;
      userStore.setBlockedUser({
        _userName,
        isBlocked: true,
      });
    } else if (response.status === HTTPStatus.Unauthorized) {
      const isRefreshTokenAPI = response.config.url.endsWith('auth/refresh');
      if (isRefreshTokenAPI) {
        appModule.redirectToSSOLogin();
      } else {
        await authStore.refreshToken();
        notifyService.error({ message: T.PLEASE_TRY_AGAIN });
      }
    } else if (response.status === HTTPStatus.Forbidden) {
      notifyService.error({
        message: response?.data?.errorMessage || T.PERMISSION_DENIED,
      });
    }
  }
}

export const errorHandlerService = new ErrorHandlerService();