import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { authStore } from '../../module';

const IsAuthLayer = observer(({ children }) => {
  const { isAuth } = authStore;

  return (
    <>
      { isAuth ? children : null }
    </>
  );
});

export default withRouter(IsAuthLayer);

