import { action, computed, flow, makeObservable, observable } from 'mobx';

import { downloadFileFromHttpResponse } from '../../core/helpers/fileDownloader';
import { HttpResponse } from '../../core/classes/HttpResponse';
import { partnerService } from './partnerService';

class PartnerStore {
  /** @type {HttpResponse<Partners>}*/
  partners = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<Partners>}*/
  allPartners = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<PartnerInfoResponse>}*/
  partnerInfo = new HttpResponse({});

  /** @type {HttpResponse<PartnerGameTypeLimitResponse>}*/
  partnerGameTypeLimit = new HttpResponse({ items: [], count: 0 });

  /** @type {HttpResponse<PartnersGameConfig>}*/
  partnerGameConfig = new HttpResponse({});

  partnerGameTypeLimits = new HttpResponse({ items: [], count: 0 });

  partnersWithJackpot = new HttpResponse([]);

  constructor() {
    makeObservable(this, {
      partners: observable,
      allPartners: observable,
      partnerInfo: observable,
      partnerGameTypeLimit: observable,
      partnerGameConfig: observable,
      partnerGameTypeLimits: observable,
      partnersWithJackpot: observable,
      partnerMap: computed,
      setPartners: action.bound,
      setAllPartners: action.bound,
      setPartnerInfo: action.bound,
      setPartnerGameTypeLimit: action.bound,
      setPartnersGameConfig: action.bound,
      setPartnerGameTypeLimits: action.bound,
      setPartnersWithJackpot: action.bound,
      getPartners: flow,
      getAllPartners: flow,
      getPartnerGameTypeLimit: flow,
      getPartnerGameConfig: flow,
      getPartnersGameTypeLimitsCurrencies: flow,
      getPartnersWithJackpot: flow,
    });
  }

  /**
   * @name partnerMap
   * @return {Object<number, PartnerItem>}
   */
  get partnerMap() {
    return this.allPartners.data.items.reduce((map, partner) => {
      map[partner.id] = partner;
      return map;
    }, {});
  };

  /**
   * @name setPartners
   * @param {HttpResponse<Partners>} partners
   * @returns {void}
   */
  setPartners = (partners) => {
    this.partners = partners;
  };

  /**
   * @name setAllPartners
   * @param {HttpResponse<Partners>} data
   * @returns {void}
   */
  setAllPartners = (data) => {
    this.allPartners = data;
  };

  /**
   * @name setPartnerInfo
   * @param {HttpResponse<PartnerInfoResponse>} partnerInfo
   * @returns {void}
   */
  setPartnerInfo = (partnerInfo) => {
    this.partnerInfo = partnerInfo;
  };

  /**
   * @name setPartnerGameTypeLimit
   * @param {HttpResponse<PartnerGameTypeLimitResponse>} partnerGameTypeLimit
   * @returns {void}
   */
  setPartnerGameTypeLimit = (partnerGameTypeLimit) => {
    this.partnerGameTypeLimit = partnerGameTypeLimit;
  };

  /**
   * @name resetPartnerGameTypeLimit
   * @returns {void}
   */
  resetPartnerGameTypeLimit = () => {
    this.setPartnerGameTypeLimit(this.partnerGameTypeLimit.fetched({ items: [], count: 0 }));
  };

  /**
   * @name setPartnersGameConfig
   * @param {HttpResponse<PartnersGameConfig>} partnerGameConfig
   * @returns {void}
   */
  setPartnersGameConfig = (partnerGameConfig) => {
    this.partnerGameConfig = partnerGameConfig;
  };

  /**
   * @name setPartnerGameTypeLimits
   * @param partnerGameTypeLimits
   */
  setPartnerGameTypeLimits = (partnerGameTypeLimits) => {
    this.partnerGameTypeLimits = partnerGameTypeLimits;
  }

  setPartnersWithJackpot = (data) => {
    this.partnersWithJackpot = data;
  }

  /**
   * @name getPartners
   * @param {PartnersParams=} params
   * @returns {Generator<*, void >}
   */
  getPartners = function* (params) {
    this.setPartners(this.partners.fetching());
    const partners = yield partnerService.getPartners(params);
    this.setPartners(this.partners.fetched(partners));
  };

  /**
   * @name getAllPartners
   * @param {PartnersParams=} params
   * @returns {Generator<*, void >}
   */
  getAllPartners = function* (params) {
    this.setAllPartners(this.partners.fetching());
    const partners = yield partnerService.getPartners(params);
    this.setAllPartners(this.partners.fetched(partners));
  };

  /**
   * @name addInternalPartner
   * @param {PartnerInternalBody} data
   * @returns {Promise<PartnerItem>}
   */
  addInternalPartner = (data) => {
    return partnerService.addInternalPartner(data);
  };

  /**
   * @name addExternalPartner
   * @param {PartnerExternalBody} data
   * @returns {Promise<PartnerExternalResponse>}
   */
  addExternalPartner = (data) => {
    return partnerService.addExternalPartner(data);
  };

  /**
   * @name getPartnerInfo
   * @param {number} id
   * @returns {Promise<PartnerInfoResponse>}
   */
  getPartnerInfo = async function (id) {
    this.setPartnerInfo(this.partnerInfo.fetching());
    const info = await partnerService.getPartnerInfo(id);
    this.setPartnerInfo(this.partnerInfo.fetched(info));
    return info;
  };

  /**
   * @name getPartnerGameTypeLimit
   * @param {number} id
   * @param {PartnerGameTypeLimitParams=} params
   * @returns {Generator<Promise<PartnerGameTypeLimitResponse>, void, *>}
   */
  getPartnerGameTypeLimit = function* (id, params) {
    this.setPartnerGameTypeLimit(this.partnerGameTypeLimit.fetching());
    const partnerLimit = yield partnerService.getPartnerGameTypeLimitById(id, params);
    this.setPartnerGameTypeLimit(this.partnerGameTypeLimit.fetched(partnerLimit));
  };

  /**
   * @name editGameTypeLimit
   * @param {number} id
   * @param {EditPartnerGameTypeLimitBody} data
   * @returns {Promise<void>}
   */
  editGameTypeLimit = (id, data) => {
    return partnerService.editPartnerGameTypeLimitById(id, data);
  };

  /**
   * @name getPartnerGameConfig
   * @param {number} id
   * @param {number} gameId
   * @returns {Generator<Promise<PartnersGameConfig>, void, *>}
   */
  getPartnerGameConfig = function* (id, gameId) {
    this.setPartnersGameConfig(this.partnerGameConfig.fetching());
    const partner = yield partnerService.getPartnersGameConfigById(id, gameId);
    this.setPartnersGameConfig(this.partnerGameConfig.fetched(partner));
  };

  /**
   * @name getPartnerGameTypeLimits
   * @param {GetPartnerGameTypeLimitsParams=} params
   * @return {Generator<Promise<GameTypesLimitsResponse>, void, *>}
   */
  getPartnersGameTypeLimitsCurrencies = function* (params) {
    this.setPartnerGameTypeLimits(this.partnerGameTypeLimits.fetching());
    const partnerGameTypeLimits = yield partnerService.getPartnersGameTypeLimitsCurrencies(params);
    this.setPartnerGameTypeLimits(this.partnerGameTypeLimits.fetched(partnerGameTypeLimits));
  }

  getPartnersWithJackpot = function* (params) {
    this.setPartnersWithJackpot(this.partnersWithJackpot.fetching());
    const data = yield partnerService.getPartners(params);
    this.setPartnersWithJackpot(this.partnersWithJackpot.fetched(data.items));
  }

  /**
   * @name editPartners
   * @param {PartnerRequestBody} data
   * @returns {Promise<void>}
   */
  editPartners = (data) => {
    return partnerService.editPartners(data);
  };

  /**
   * @name editPartnerGames
   * @param {EditPartnerGamesBody} data
   * @return {Promise<void>}
   */
  editPartnerGames = (data) => {
    return partnerService.editPartnerGames(data);
  }

  /**
   * @name editPartnerGameConfig
   * @param {number} id
   * @param {PartnersGameConfig} data
   * @returns {Promise<void>}
   */
  editPartnerGameConfig = (id, data) => {
    return partnerService.editPartnersGameConfig(id, data);
  };

  /**
   * @name exportPartnersGameTypeLimits
   * @param {number} id
   * @param {ExportPartnersGameTypeLimitsParams} params
   * @return {Promise<File>}
   */
  exportPartnersGameTypeLimits = async (id, params) => {
    const res = await partnerService.exportPartnersGameTypeLimits(id, params);
    if (!res.ok) {
      throw new Error('Something went wrong while exporting.');
    }
    try {
      downloadFileFromHttpResponse(res, 'partnerGameTypeLimits.xlsx');
    } catch (error) {
      console.error(error);
    }
    return res;
  };

  exportPartnerGameTypeLimitsCurrencies = async (id, params) => {
    const res = await partnerService.exportPartnerGameTypeLimitsCurrencies(id, params);
    if (!res.ok) {
      throw new Error('Something went wrong while exporting.');
    }
    try {
      downloadFileFromHttpResponse(res, 'partnerGameTypeLimits.xlsx');
    } catch (error) {
      console.error(error);
    }
    return res;
  };

  /**
   * @param {number} id
   * @param {ImportPartnersGameTypeLimits} formData
   * @return {Promise<*>}
   */
  importPartnersGameTypeLimits = (id, formData) => {
    return partnerService.importPartnersGameTypeLimits(id, formData);
  }

  /**
   * @name createPartnerGameTypeLimitsFormData
   * @param {ImportPartnersGameTypeLimits} values
   * @return {FormData}
   */
  createPartnerGameTypeLimitsFormData = (values) => {
    const formData = new FormData();
    const { blob, fileName } = values.limitsExcel || {};
    formData.append('gameTypeId', values.gameTypeId);
    formData.append('limitsExcel', blob, fileName);
    return formData;
  }

  resetPartnerGameTypeLimits = () => {
    this.setPartnerGameTypeLimits(this.partnerGameTypeLimits.fetched({ items: [], count: 0 }));
  }

}

export const partnerStore = new PartnerStore();
